import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { QRCodeCanvas } from 'qrcode.react'; // Correct import
import { Box, Typography, Card, CardContent, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import './EventPage.css';

export const GET_USER_EVENTS = gql`
  query GetUserEvents {
    getUserEvents {
      id
      requiresId
      startDate
      endDate
      type
      venue
      name
      eventMedia {
        mediaUrl
      }
      description
      qrData
    }
  }
`;

const EventPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data, loading, error } = useQuery(GET_USER_EVENTS);

  if (loading) return <Typography>Loading event details...</Typography>;
  if (error) return <Typography color="error">Error loading event: {error.message}</Typography>;

  const event = data?.getUserEvents?.find((event) => event.id === id);

  if (!event) {
    return <Typography color="error">Event not found.</Typography>;
  }

  return (
    <Box className="event-page" sx={{ padding: 3 }}>
      <Button onClick={() => navigate(-1)} sx={{ mb: 2, color: '#333' }}>
        <FontAwesomeIcon icon={faArrowLeft} />  Back
      </Button>
      <Card sx={{ maxWidth: 600, margin: 'auto', boxShadow: 3 }}>
        <CardContent>
          <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2, textAlign: 'center' }}>
            {event.name}
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>Type:</strong> {event.type}
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>Venue:</strong> {event.venue}
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>Start Date:</strong> {new Date(event.startDate).toLocaleString()}
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>End Date:</strong> {new Date(event.endDate).toLocaleString()}
          </Typography>
          <Typography variant="body2" sx={{ mt: 2, mb: 2 }}>
            {event.description}
          </Typography>
          <Box sx={{ textAlign: 'center', mt: 3 }}>
            <QRCodeCanvas value={event.qrData} size={200} />
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default EventPage;
